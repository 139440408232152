import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Seo } from '../components/seo/seo';
import { LocalImagesContext } from '@matix/utils';

export default (props: any) => {
   const localImagesDesktop = props.data.localImagesDesktop.edges.map((edge: any) => {
      return { ...edge.node, IsMobile: false };
   });
   const localImagesMobile = props.data.localImagesMobile.edges.map((edge: any) => {
      return { ...edge.node, IsMobile: true };
   });

   props.data.seoData = {
      title: props.data.template.frontmatter.title,
      description: props.data.template.frontmatter.description,
      keywords: props.data.template.frontmatter.keywords,
      locale: props.data.template.frontmatter.locale,
      breadcrumbs: props.data.template.frontmatter.breadcrumbs,
   };

   return (
      <LocalImagesContext.Provider
         value={{
            images: [...props.data.template.eagerImages, ...props.data.template.lazyImages, ...localImagesDesktop, ...localImagesMobile],
            basePath: props.pageContext.localImageBasePath,
         }}
      >
         <Seo {...props} />
         <MDXRenderer {...props.data}>{props.data.template.body}</MDXRenderer>
      </LocalImagesContext.Provider>
   );
};

export const pageQuery = graphql`
   query mdxQueryPageTemplate($pageMdx: String, $locale: String, $localImages: [String]) {
      template: mdx(frontmatter: { pageMdx: { eq: $pageMdx }, locale: { eq: $locale } }) {
         id
         body
         ...ImageLazyEagerFragment
         ...SeoFragment
      }

      localImagesDesktop: allFile(filter: { sourceInstanceName: { eq: "images" }, name: { in: $localImages } }) {
         edges {
            node {
               relativePath
               name
               childImageSharp {
                  fluid(maxWidth: 930, quality: 75) {
                     ...GatsbyImageSharpFluid_withWebp_noBase64
                     presentationWidth
                  }
               }
            }
         }
      }

      localImagesMobile: allFile(filter: { sourceInstanceName: { eq: "images" }, name: { in: $localImages } }) {
         edges {
            node {
               relativePath
               name
               childImageSharp {
                  fluid(maxWidth: 360, quality: 75) {
                     ...GatsbyImageSharpFluid_withWebp_noBase64
                     presentationWidth
                  }
               }
            }
         }
      }

      grouping: allMdx(filter: { frontmatter: { type: { eq: "product" }, locale: { eq: $locale } } }) {
         categories: group(field: frontmatter___category) {
            fieldValue
            totalCount
         }
         providers: group(field: frontmatter___provider) {
            fieldValue
            totalCount
         }
      }
   }
`;
